import { useRef, useEffect } from 'react';
import classNames from 'classnames';

import config from 'config';
import Logo from '@templates/Logo';
import GlassLayer from '@templates/GlassLayer';
import InputSearch from '@components/InputSearch';
import SearchIcon from 'assets/icons/search.svg';
import LongArrow from 'assets/icons/arrow-long.svg';
import MenuExit from 'assets/icons/menu-exit.svg';
import { useIntl } from 'utils/intl';
import useBoolean from 'hooks/useBoolean';
import useOutsideClick from 'hooks/useOutsideClick';
import useMediaQuery from 'hooks/useMediaQuery';

import Results from './components/Results';
import messages from './SearchBox.messages';
import classes from './SearchBox.module.scss';
import Props from './SearchBox.types';

const { defaultMinPhraseLength } = config;

const SearchBox = ({
  classes: customClasses,
  results,
  meta,
  placeholder,
  value,
  loading,
  withAutofocus = true,
  onFocus,
  onSearch,
  onChange,
  onItemClick,
}: Props): JSX.Element => {
  const intl = useIntl();
  const isDesktop = useMediaQuery('small', 'up');
  const inputRef = useRef<HTMLInputElement>(null);
  const resultRef = useRef<HTMLDivElement>(null);
  const [resultShown, { on: showResult, off: hideResult }] = useBoolean(false);
  const [focused, { setValue: setFocused }] = useBoolean(false);

  const trimmedValue = value.trim();

  useOutsideClick(
    resultRef,
    () => {
      if (resultShown) {
        hideResult();
      }
    },
    [resultShown]
  );

  const handleFocus = (focusValue: boolean) => {
    setFocused(focusValue);
    onFocus?.(focusValue);
  };

  const handleFocusIn = () => {
    showResult();
    handleFocus(true);
  };

  const handleSearch = () => {
    if (trimmedValue) {
      onChange(trimmedValue);
      onSearch(trimmedValue);
      hideResult();
    }
  };

  useEffect(() => {
    if (isDesktop && withAutofocus) {
      inputRef.current?.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form
      className={classNames(customClasses?.root, { [classes.focused]: focused })}
      action="/search"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      {focused && (
        <h1 className={classes.logoWrapper}>
          <Logo classes={{ link: classes.logoAnchor, image: classes.logo }} withLink />
          <MenuExit className={classes.closeIcon} onClick={() => handleFocus(false)} />
        </h1>
      )}
      <div className={classNames(classes.wrapper, { [classes.searchBoxActive]: focused || resultShown })}>
        <SearchIcon className={classNames(classes.icon, { [classes.hiddenSearchIcon]: !focused && !resultShown })} />
        <InputSearch
          ref={inputRef}
          value={value}
          className={classNames({ [classes.inputSearch]: focused || resultShown })}
          placeholder={placeholder || intl.formatMessage(messages.placeholder)}
          onChange={(changeValue: string) => {
            onChange(changeValue);
            showResult();
          }}
          onFocus={handleFocusIn}
          onBlur={() => {
            onChange(trimmedValue);

            if (isDesktop) {
              handleFocus(false);
            }
          }}
        />
        {focused || resultShown ? (
          <button type="submit" className={classes.submit}>
            <LongArrow className={classNames([classes.iconRight, classes.icon])} aria-label="strzałka" />
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              inputRef.current?.focus();
              handleFocusIn();
            }}
            className={classes.submit}
          >
            <SearchIcon className={classNames([classes.iconRight, classes.icon])} aria-label="lupa" />
          </button>
        )}
        {(resultShown || focused || loading) && (
          <div className={classNames(classes.dropdown, { [classes.loading]: loading })} ref={resultRef}>
            {loading && <GlassLayer loaderSize={isDesktop ? 30 : 40} className={classes.glassLayer} />}
            {trimmedValue.length >= defaultMinPhraseLength && results && (
              <Results
                searchPhrase={trimmedValue}
                results={results}
                meta={meta}
                loading={loading}
                onResultClick={hideResult}
                onResultLinkClick={(e, id, categoryId) => {
                  onItemClick?.(e, id, categoryId);
                  if (!isDesktop) {
                    handleFocus(false);
                  }
                  hideResult();
                }}
              />
            )}
          </div>
        )}
      </div>
    </form>
  );
};

export default SearchBox;
